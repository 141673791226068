<template>
  <div v-if="category">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <h1 class="header">
      {{category.name}}
    </h1>
    <div v-if="popularFood && popularFood.length">
      <h3 class="headline">Popular ingredients</h3>
      <p>Explore recipes by popular ingredients.</p>
      <IngredientGrid :items="popularFood" />
    </div>
    
    <div v-if="category.subCategories && category.subCategories.length">
      <h3 class="headline mt-4">Browse sub category</h3>
      <p>Explore all ingredients by their category.</p>
      <GenericCardGrid :items="category.subCategories" v-slot="slotProps">
        <v-card color="secondary" dark :to="{name: 'ingredientCategory', params: {id: slotProps.item.id}}">
          <v-card-title :class="{'title-s' : $vuetify.breakpoint.xs }">
            {{slotProps.item.name}}
          </v-card-title>
        </v-card>
      </GenericCardGrid>
    </div>

  </div>
</template>

<script>
import IngredientGrid from "@/components/IngredientGrid.vue";
import GenericCardGrid from "@/components/GenericCardGrid.vue";
import foodService from "@/services/foodService";

export default {
  name: "Ingredients",
  components: {
    IngredientGrid,
    GenericCardGrid,
  },
  props: {
    msg: String
  },
  data() {
    return {
      popularFood: null,
      category: null,
    };
  },
  async mounted() {
    await this.loadData(this.$route.params.id);
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    this.loadData(to.params.id);
    next();
  },
  computed: {
    breadcrumbItems() {
      return this.category == null ? null : [
        { text: 'Ingredients', href: '/ingredients' },
        this.category.parent == null ? null : { text: this.category.parent.name, to: {name: 'ingredientCategory', params: {id: this.category.parent.id}} },
        { text: this.category.name, disabled: true },
      ].filter(x =>x != null);
    },
  },
  methods: {
    async loadData(category) {
      var result = await foodService.getCategory(category);
      this.category = result.data;
      
      result = await foodService.getPopular(category, 25)
      this.popularFood = result.data.data;
    }
  },
  watch: {
  }
};
</script>
